// IMPORT ADDONS
@import '../vendors/bootstrap/css/bootstrap';
@import '../vendors/font-awesome/font-awesome';

/* purgecss start ignore */
@import '../vendors/owl-carousel2/owl.carousel';
@import '../vendors/magnific-popup/magnific-popup';
// @import '../vendors/cube-portfolio/cubeportfolio';
@import 'styles/alertify';
/* purgecss end ignore */

// IMPORT CUSTOMIZATION & RESET (order is important)
@import 'core/reset';
@import 'variables';
@import 'core/html';
@import 'utilities/functions';

// *******************
// Load basic classes (besoin de rester ici à la fin)
@import 'styles/fonts'; // utility classes for fonts
@import 'utilities/spacing'; // utility classes for spacing
@import 'utilities/flex'; // utility classes for flex box
@import 'utilities/responsive';
@import 'utilities/animation';

@import 'styles/colors'; // utility classes for colors
@import 'styles/hover';
@import 'styles/typography';
@import 'styles/buttons';

/* GENERAL TEMPLATE */
body {
    background-color: $bg-color-body;

    // to fix parallax bug on iphone...
    .parallax {
        background-attachment: fixed;
        @media screen and (max-width: $size-xs-max) {
            background-attachment: scroll;
        }
    }
}

/** Add this class to any anchor tag so it clear the fixed menu height (used in forms) */
.jumptarget::before {
    content: "";
    display: block;
    height: $size-nav-height; /* fixed header height*/
    margin: -$size-nav-height 0 0; /* negative fixed header height */
}

/** Adds a shadow to the div element **/
.shadow {
    position: relative;

    /*&::after {
        content: '';
        z-index: 0;
        width: 100%;
        top: 100%;
        height: 40px;
        position: absolute;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }*/
}

/*****************************************************/
/* Menu (to use with /modules/module-menu.php)
/* Or /modules/module-menu-centre.php
/* Or /modules/module-menu-etage.php
 */
/*****************************************************/
@import 'modules/menu';
// @import 'menu-centre';
// @import 'menu-etage';


/*****************************************************/
/* Footer (to use with /modules/module-footer.php)
/*****************************************************/
@import 'styles/footer';

/*****************************************************/
/* Sections (to use with /sections/section-*-*.php)
/*****************************************************/
@import 'sections/section-header-page';

//@import 'sections/section-text-image';

@import 'sections/section-bursts';

/*****************************************************/
/* Modules (to use with /modules/module-*-*.php)
/*****************************************************/
@import 'modules/module-slideshow';
@import 'modules/module-header';
@import 'modules/module-soumission';



/*******************************************************************************
* COMMON TO ALL PAGES
*/

h1, h2, h3, h4, h5, p, li, a, div {
   font-family: 'Barlow' !important, ;
}

/*****************************************************/
/* INDEX.PHP
/*****************************************************/

/***** Menu *****/

nav.module-menu .menu-navbar ul.main-menu li.item {
    padding: 0 0.5rem;
}
nav.module-menu .menu-navbar ul.main-menu li.item:first-child {
    padding: 0 0.5rem 0 0;
}
nav.module-menu .menu-navbar ul.main-menu li.item.dropdown {
    transition: all 0.3s ease;
}
nav.module-menu .menu-navbar ul.main-menu li.item.dropdown:hover > .submenu {
    opacity: 100;
    visibility: visible;
}
nav.module-menu .menu-navbar ul.main-menu li.item.dropdown .submenu {
    background-color: #ed1b24;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 55px;
    left: 0;
    display: block;
    min-width: 100%;
    padding: 0px;
    transition: opacity 0.3s ease-in;
    z-index: 505;
}
nav.module-menu .menu-navbar.shadow.flex.flex-column.nav-visible::before {
    content: '';
    width: 357px;
    height: 500px;
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.3s all ease-in-out;
    background-image: url(../images/accueil_header_img01.png);
}
nav.module-menu.scrolled .menu-navbar.shadow.flex.flex-column.nav-visible::before {
    display: none;
    transition: 0.3s all ease-in-out;
}
nav.module-menu.scrolled .menu-navbar {
    height: 217px;
}
nav.module-menu .menu-navbar .lowerpart.w100.flex.justify-between.px4 {
    padding-left: 24.8rem;
}
nav.module-menu .menu-navbar .upperpart .main-menu-top p {
    margin-top: -24px;
    font-weight: normal;
}
/*nav.module-menu ul.main-menu li.item a div span, nav.module-menu ul.main-menu li.item.dropdown a span{
    font-weight: 500;
}*/
.main-menu li.lang div {
    display: none;
}
nav.module-menu ul.main-menu li.item a div, nav.module-menu ul.main-menu li.item.dropdown a {
    font-weight: 300;
    color: #080808;
    font-size: 19px;
    @media screen and (max-width: 1550px){
      font-size: 16px;
    }
}
nav.module-menu ul.main-menu li.item a:hover, nav.module-menu ul.main-menu li.item a div:hover, .main-menu li.lang div:hover {
    color: #ed1b24;
}
nav.module-menu ul.main-menu li.item .submenu ul li.subitem a div {
    color: #fff;
    text-transform: initial;
    font-size: 16px;
}
nav.module-menu ul.main-menu li.item .submenu ul li.subitem a div:hover {
    color: #080808;
}
nav.module-menu ul.main-menu li.item .submenu ul li.subitem {
    border-bottom: 2px solid #fff;
    padding-left: 20px;
}
nav.module-menu ul.main-menu li.item .submenu ul li.subitem:last-child {
    border-bottom: none;
}
nav.module-menu ul.main-menu li.item .submenu ul {
    padding: 0;
}
nav.module-menu .menu-navbar .main-menu.right ul {
    padding: 0;
    display: flex;
}
nav.module-menu .menu-navbar .main-menu.right ul li.icon.menuIcon a img.hoverable.iconHover {
    display: none;
}
nav.module-menu .menu-navbar .main-menu.right ul li.icon.menuIcon:hover a img.hoverable.iconHover {
    display: initial;
}
nav.module-menu .menu-navbar .main-menu.right ul li.icon.menuIcon:hover a img.hoverable {
    display: none;
}
nav.module-menu .menu-navbar .main-menu.right ul li.icon.menuIcon {
    padding-left: 20px;
    margin-top: 5px;
}

/***** Bannière *****/

#hero {
    position: relative;
}
#hero .slideshow-wrap.ls-direction-fix .ls-fullscreen-wrapper::before {
    content: '';
    width: 949px;
    height: 1050px;
    position: absolute;
    top: 0px;
    right: 0px;
    background-image: url(../images/accueil_header_img02.png);
    z-index: 45;
}
#hero .slideshow-wrap .caption .row .col-xs-12 h2 {
    font-size: 5rem;
    font-weight: 300;
    padding-bottom: 28px;
}
#hero .slideshow-wrap .caption .row .col-xs-12 h2 span {
    font-weight: 500;
    color: #fff;
}
#hero .slideshow-wrap .caption .row .col-xs-12 img.fleche {
    display: block;
    margin-top: 20px;
    margin-left: 58px;
}

/***** Bloc Principal *****/

#blocPrincipal {
    padding: 110px 70px 30px 70px;
    background-image: url(../images/accueil_section01_bg.jpg);
    position: relative;
}
#blocPrincipal .blocProduit {
    float: left;
    width: 55%;
    border-right: 4px solid #ee2f37;
}
#blocPrincipal .blocProduit .bloc.blocGauche {
    float: left;
    padding-right: 45px;
}
#blocPrincipal .blocProduit .bloc {
    position: relative;
    overflow: hidden;
    transition: 0.3s all ease-in-out;
}
#blocPrincipal .blocBeton {
    width: 45%;
    float: left;
    padding-left: 90px;
}
#blocPrincipal .blocBourgeois {
    clear: both;
    text-align: center;
    padding: 80px 0 160px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    ul {
        margin-left: -4.5%;
        display: flex;
        flex-direction: column;
        align-items: baseline;
        li {
            font-weight: normal;
            font-size: $font-size-24;
            color: #000;
            text-transform: uppercase;
            line-height: 1.3;
            list-style: none;
            span {
                padding-right: 25px;
            }
        }
    }
}
#blocPrincipal .blocBourgeois .blocLien {
    text-align: initial;
    position: absolute;
    right: 35px;
    bottom: 0px;
}
#blocPrincipal .blocProduit h3, #blocPrincipal .blocBeton h3 {
    font-weight: 300;
    text-transform: uppercase;
    color: #080808;
    font-size: 1.5rem;
    text-align: center;
    margin-bottom: 25px;
}
#blocPrincipal .blocProduit span, #blocPrincipal .blocBeton span {
    font-weight: 600;
    color: #ed1b24;
}
#blocPrincipal .blocProduit p {
    font-weight: 300;
    width: 90%;
    margin-bottom: 30px;
    line-height: 1.5;
}
#blocPrincipal .blocProduit .bloc a h4 {
    font-weight: 300;
    text-transform: uppercase;
    font-size: 1.75rem;
    position: relative;
    left: 50px;
    padding-bottom: 50px;
    margin-top: 10px;
    color: #080808;
}
#blocPrincipal .blocProduit .bloc a img.imgHover {
    display: none;
}
#blocPrincipal .blocProduit .bloc a:hover img.imgHover {
    display: block;
}
#blocPrincipal .blocProduit .bloc a:hover img.imgPrincipal {
    display: none;
}
#blocPrincipal .blocProduit .bloc h4::before {
    content: '';
    width: 13px;
    height: 142px;
    position: absolute;
    top: -95px;
    left: -30px;
    background-image: url(../images/accueil_section01_lignerouge.jpg);
}
#blocPrincipal .blocBeton a {
    font-weight: 500;
    color: #10100f;
    padding: 35px 0 60px 0;
    text-align: center;
    display: block;
    font-size: 20px;
}
#blocPrincipal .blocBeton a:hover {
    color: #ed1b24;
}
#blocPrincipal .blocBeton::before {
    content: '';
    width: 406px;
    height: 808px;
    position: absolute;
    bottom: 27.9%;
    right: 0px;
    background-image: url(../images/accueil_section02_img03.png);
    z-index: 45;
}
#blocPrincipal .blocBeton .blocInstallation h4.installations {
    float: left;
    text-transform: uppercase;
    color: #ed1b24;
    font-weight: 600;
    font-size: 20px;
    padding-right: 30px;
    border-right: 2px solid #000;
}
#blocPrincipal .blocBeton .blocInstallation h4.capacites {
    text-transform: uppercase;
    color: #ed1b24;
    font-weight: 600;
    font-size: 20px;
    padding-left: 30px;
    overflow: hidden;
}
#blocPrincipal .blocBeton .blocInstallation .blocTitre {
    display: flex;
    justify-content: center;
    padding-bottom: 10px;
}
#blocPrincipal .blocBeton .blocInstallation ul li {
    font-weight: normal;
    color: #0b0b0b;
    font-size: 19px;
    text-transform: initial;
    line-height: 1.5;
    padding-bottom: 10px;
}
#blocPrincipal .blocBeton .blocInstallation ul {
   position: relative;
   left: 25%;
   list-style: none;
   width: 65%;
}
#blocPrincipal .blocBeton .blocInstallation ul li::before {
    content: "■";
    color: #ed1b24;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
}
#blocPrincipal .blocBeton .blocInstallation img.img-responsive.imgSection02 {
    margin-top: 55px;
}
#blocPrincipal .blocBourgeois {
    position: relative;
}
#blocPrincipal .blocBourgeois h3 {
    font-weight: 600;
    font-size: 2rem;
    color: #ed1b24;
    margin-bottom: 15px;
}
#blocPrincipal .blocBourgeois h3 span {
    font-weight: 300;
    color: #080808;
}
#blocPrincipal .blocBourgeois p {
    font-weight: normal;
    line-height: 1.3;
    padding-bottom: 20px;
}
#blocPrincipal .blocBourgeois::before {
    content: '';
    width: 623px;
    height: 473px;
    position: absolute;
    bottom: -31px;
    left: -70px;
    background-image: url(../images/accueil_section03_img01.png);
}
#blocPrincipal .blocBourgeois a.lien {
    color: #080808;
    font-weight: 300;
    font-size: 1.45rem;
    position: relative;
    bottom: -100px;
}
#blocPrincipal .blocBourgeois a.lien:hover {
    color: #ed1b24;
}
#blocPrincipal .blocBourgeois a.lien::before {
    content: '';
    width: 142px;
    height: 13px;
    position: absolute;
    bottom: -15px;
    right: -75px;
    background-image: url(../images/accueil_section03_lignerouge.jpg);
}
#blocPrincipal .blocBourgeois .blocLien .images img.img-responsive.logoBourgeois {
    padding-bottom: 25px;
}
#blocPrincipal .blocBourgeois .blocLien .images img.logoQuali {
    padding-right: 70px;
}

/***** Bloc Footer *****/

#blocFooter {
    display: flex;
    background: #31312f;
    padding: 50px 70px;
}
#blocFooter .blocGauche {
    width: 75%;
}
#blocFooter .blocDroite {
    width: 25%;
}
#blocFooter .blocGauche .blocMenu {
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    width: 88%;
    padding: 15px 0;
    margin-bottom: 30px;
}
#blocFooter .blocGauche .blocMenu a {
    padding-right: 20px;
}
#blocFooter .blocGauche .blocMenu a:last-child {
    padding-right: 0px;
}
#blocFooter .blocGauche .bloc {
    display: flex;
    width: 22%;
    flex-direction: column;
    float: left;
}
#blocFooter .blocGauche .bloc a {
    padding-bottom: 10px;
    color: #ffffff;
    font-weight: 300;
    font-size: 19px;
}
#blocFooter .blocGauche .blocMenu a {
    color: #ffffff;
    font-weight: 300;
    font-size: 19px;
}
#blocFooter .blocGauche .blocMenu a:hover, #blocFooter .blocGauche .bloc a:hover {
    font-weight: 500;
}
#blocFooter .blocDroite .blocTexte h4 {
    color: #fff;
    font-weight: 300;
    text-transform: uppercase;
    font-size: 1.25rem;
    margin-bottom: 30px;
}
#blocFooter .blocDroite .blocTexte p {
    color: #fff;
    font-weight: 300;
    line-height: 1.35;
    font-size: 22px;
    padding-bottom: 20px;
}
#blocFooter .blocDroite .blocTexte p:last-child {
    padding-bottom: 0px;
    position: absolute;
    right: 145px;
}
#blocFooter .blocDroite .blocTexte p.bureau {
    padding-bottom: 0px;
    margin: 0;
}

/*******************************************************************************
* Page Béton Préparé
*******************************************************************************/

nav.module-menu .menu-navbar.nav-visible.shadow.flex.flex-column.menu6::before {
    content: '';
    width: 357px;
    height: 500px;
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.3s all ease-in-out;
    background-image: url(../images/betonprepare_header_img01.jpg);
}
#blocPrincipal_prepare {
    padding: 13% 25% 0 25%;
    background-image: url(../images/betonprepare_section01_bg.jpg);
    background-size: cover;
    position: relative;
}
#blocPrincipal_prepare::before {
    content: '';
    width: 762px;
    height: 1237px;
    position: absolute;
    right: 0;
    top: 1%;
    transition: 0.3s all ease-in-out;
    background-image: url(../images/betonprepare_header_img02.png);
}
#blocPrincipal_prepare .titre h2 {
    font-size: 3.8rem;
    font-weight: 300;
}
#blocPrincipal_prepare img.img-responsive.ligneRouge {
    position: absolute;
    left: 13%;
    top: 14.5%;
}
#blocPrincipal_prepare .titre h3{
    font-weight: 500;
    font-size: 3.8rem;
    color: #fff;
    background: #e11a22;
    padding: 0 0 60px 0px;
    width: 70%;
}
#blocPrincipal_prepare .blocIntro p {
    font-weight: 400;
    color: #0b0b0b;
    font-size: 1rem;
    line-height: 1.5;
    margin: 45px 0;
    width: 58%;
}
#blocPrincipal_prepare .titreFabriquons h3, #blocPrincipal_prepare .titreInstallations h3 {
    color: #080808;
    font-size: 2.5rem;
    font-weight: 300;
    text-transform: uppercase;
}
#blocPrincipal_prepare .titreFabriquons h3, #blocPrincipal_prepare .titreInstallations h3{
    font-weight: 400;
}
#blocPrincipal_prepare .titreFabriquons, #blocPrincipal_prepare .titreInstallations {
    padding: 100px 0 30px 0;
}
#blocPrincipal_prepare .liste {
    display: flex;
    justify-content: space-between;
}
#blocPrincipal_prepare .liste ul li, #blocPrincipal_prepare .liste_installations ul li{
    font-weight: 400;
    color: #0b0b0b;
    font-size: 1rem;
    line-height: 1.5;
    margin-bottom: 15px;
    text-transform: initial;
    background-image: url(../images/betonprepare_section01_icon_checkmark.png) no-repeat;
    list-style: none;
    padding-left: 55px;
}
#blocPrincipal_prepare .blocLogo {
    padding: 120px 0 30px 0;
    display: flex;
}
#blocPrincipal_prepare .blocLogo h4 {
    color: #ed1b24;
    text-transform: uppercase;
    font-size: 2rem;
}
#blocPrincipal_prepare .blocLogo .titre {
    width: 70%;
}
#blocPrincipal_prepare .blocLogo .bourgeois {
    position: relative;
    left: 25%;
}
#blocPrincipal_prepare .liste_installations::before {
    content: '';
    width: 504px;
    height: 720px;
    position: absolute;
    right: 0;
    top: 61%;
    transition: 0.3s all ease-in-out;
    background-image: url(../images/betonprepare_section01_img02.png);
}
.main-content {
    position: relative;
    .item {
        width: 70%;
    }
    .owl-theme {
        .custom-nav {
            position: absolute;
            top: 20%;
            left: 0;
            right: 0;
            .owl-prev, .owl-next {
                position: absolute;
                height: 100px;
                color: inherit;
                background: none;
                border: none;
                z-index: 100;
                i {
                    font-size: 2.5rem;
                    color: #ed1b24;
                }
            }
            .owl-prev {
                left: 0;
                top: 115px;
            }
            .owl-next {
                right: 30%;
                top: 115px;
            }
        }
    }
}

/*******************************************************************************
* Page Béton Préfabriqué
*******************************************************************************/

nav.module-menu .menu-navbar.nav-visible.shadow.flex.flex-column.menu5::before {
    content: '';
    width: 357px;
    height: 500px;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    transition: 0.3s all ease-in-out;
    background-image: url(../images/betonprefabrique_header_img01.png);
}
#blocPrincipal_prefabrique {
    padding: 13% 25% 2% 25%;
    position: relative;
}
#blocPrincipal_prefabrique::before {
    content: '';
    width: 544px;
    height: 827px;
    position: absolute;
    right: 0;
    top: 1%;
    transition: 0.3s all ease-in-out;
    background-image: url(../images/betonprefabrique_section01_pho01.png);
}
#blocPrincipal_prefabrique .titre h2 {
    font-size: 3.8rem;
    font-weight: 300;
}
#blocPrincipal_prefabrique img.img-responsive.ligneRouge {
    position: absolute;
    left: 13%;
    top: 41%;
}
#blocPrincipal_prefabrique .titre h3{
    font-weight: 500;
    font-size: 3.8rem;
    color: #fff;
    background: #e11a22;
    padding: 0 0 60px 0px;
    width: 70%;
}
#blocPrincipal_prefabrique .texte p {
    font-weight: 400;
    color: #0b0b0b;
    font-size: 1rem;
    line-height: 1.5;
    margin: 45px 0 20px 0;
    width: 58%;
}
#blocPrincipal_prefabrique .texte p:last-child {
    margin: 25px 0; 
}
#blocIntro_prefabrique .texte {
    margin-top: 35px;
}

/*******************************************************************************
* Page Ponceaux Rectangulaire
*******************************************************************************/

nav.module-menu .menu-navbar.nav-visible.shadow.flex.flex-column.menu7::before {
    content: '';
    width: 357px;
    height: 500px;
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.3s all ease-in-out;
    background-image: url(../images/ponceaux_header_img01.jpg);
}
#blocPrincipal_ponceaux {
    padding: 13% 25% 20% 25%;
    background-image: url(../images/betonprepare_section01_bg.jpg);
    background-size: cover;
    position: relative;
}
#blocPrincipal_ponceaux::before {
    content: '';
    width: 762px;
    height: 1237px;
    position: absolute;
    right: 0;
    top: 1%;
    transition: 0.3s all ease-in-out;
    background-image: url(../images/ponceaux_section01_img01.png);
}
#blocPrincipal_ponceaux .titre h2 {
    font-size: 3.8rem;
    font-weight: 300;
}
#blocPrincipal_ponceaux img.img-responsive.ligneRouge {
    position: absolute;
    left: 13%;
    top: 22%;
}
#blocPrincipal_ponceaux .titre h3{
    font-weight: 500;
    font-size: 3.8rem;
    color: #fff;
    background: #e11a22;
    padding: 0 0 60px 0px;
    width: 70%;
}
#blocPrincipal_ponceaux .blocIntro p {
    font-weight: 400;
    color: #0b0b0b;
    font-size: 1rem;
    line-height: 1.5;
    margin: 45px 0;
    width: 58%;
}
#blocPrincipal_ponceaux .titreFabriquons_ponceaux h3 {
    color: #080808;
    font-size: 2.5rem;
    font-weight: 300;
    text-transform: uppercase;
}
#blocPrincipal_ponceaux .titreFabriquons_ponceaux h3 span {
    font-weight: 500;
}
#blocPrincipal_ponceaux .titreFabriquons_ponceaux {
    padding: 50px 0 30px 0;
}
#blocPrincipal_ponceaux .liste ul li {
    font-weight: 400;
    color: #0b0b0b;
    font-size: 1rem;
    line-height: 1.5;
    margin-bottom: 15px;
    text-transform: initial;
    background: url(../images/betonprepare_section01_icon_checkmark.png) no-repeat;
    list-style: none;
    padding-left: 55px;
}
.blocLogo_ponceaux {
    position: absolute;
    right: 2%;
    top: 91%;
}

/*******************************************************************************
* Page Glissières
*******************************************************************************/

nav.module-menu .menu-navbar.nav-visible.shadow.flex.flex-column.menu4::before {
    content: '';
    width: 477px;
    height: 575px;
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.3s all ease-in-out;
    background-image: url(../images/glissierejersey_header_img01.png);
}
#blocPrincipal_glissiere {
    padding: 13% 25% 0 25%;
    background-image: url(../images/glissierejersey_section01_bg.jpg);
    background-size: cover;
    position: relative;
}
#blocPrincipal_glissiere::before {
    content: '';
    width: 450px;
    height: 1210px;
    position: absolute;
    right: 0;
    top: 0%;
    transition: 0.3s all ease-in-out;
    background-image: url(../images/glissierejersey_header_img02.png);
}
#blocPrincipal_glissiere .titre h2 {
    font-size: 5rem;
    font-weight: 300;
}
#blocPrincipal_glissiere img.img-responsive.ligneRouge {
    position: absolute;
    left: 13%;
    top: 20%;
}
#blocPrincipal_glissiere .titre h3{
    font-weight: 500;
    font-size: 5rem;
    color: #fff;
    background: #e11a22;
    padding: 0 0 35px 0px;
    width: 70%;
}
#blocPrincipal_glissiere .blocIntro p {
    font-weight: 400;
    color: #0b0b0b;
    font-size: 1rem;
    line-height: 1.5;
    margin-bottom: 25px;
    width: 58%;
}
#blocPrincipal_glissiere .blocIntro p:nth-child(1) {
    padding-top: 50px;
}
#blocPrincipal_glissiere .blocIntro p:nth-child(3) {
    padding-bottom: 50px;
}
#blocPrincipal_glissiere .titreModele h3 {
    color: #080808;
    font-size: 2.5rem;
    font-weight: 300;
    text-transform: uppercase;
}
#blocPrincipal_glissiere .titreModele h3 span {
    font-weight: 400;
}
#blocPrincipal_glissiere .blocModele {
    display: flex;
    padding: 25px 0 40px 0;
}
#blocPrincipal_glissiere .blocModele .left-side, #blocPrincipal_glissiere .blocModele .right-side {
    width: 50%;
}
#blocPrincipal_glissiere .blocModele .bloc h4 {
    font-weight: 600;
    font-size: 1rem;
}
#blocPrincipal_glissiere .blocModele .bloc p {
    font-weight: normal;
    font-size: 1rem;
    color: #e11a22;
}
#blocPrincipal_glissiere .blocLogo {
    position: relative;
    padding-bottom: 35px;
}
#blocPrincipal_glissiere .blocLogo::before {
    content: '';
    width: 618px;
    height: 591px;
    position: absolute;
    right: 85%;
    top: -468px;
    transition: 0.3s all ease-in-out;
    background-image: url(../images/glissierejersey_section01_img01.png);
}
#blocPrincipal_glissiere .blocLogo .bourgeois {
    position: relative;
    left: 100%;
}
/*******************************************************************************
* Page Réalisations
*******************************************************************************/

.caption.container.slideshow2 {
    top: 44%;
    left: 46%;
}
.caption.container.slideshow2 .row.text img.img-responsive {
    position: absolute;
    bottom: -77px;
    right: 103%;
}
#blocRealisations .bloc h4::before, #blocRealisations2 .bloc h4::before, #blocRealisations3 .bloc h4::before  {
    content: '';
    width: 13px;
    height: 142px;
    position: absolute;
    top: -95px;
    left: -30px;
    background-image: url(../images/accueil_section01_lignerouge.jpg);
}
#blocRealisations .bloc h4, #blocRealisations2 .bloc h4, #blocRealisations3 .bloc h4{
    font-weight: 300;
    font-size: 1.35rem;
    position: relative;
    left: 50px;
    padding-bottom: 50px;
    margin-top: 10px;
    padding-right: 50px;
    color: #080808;
}
#blocRealisations {
  max-width: 1900px;
  margin:auto;
  padding: 175px 3% 30px 3%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  & > div {
    max-width: 450px;
    margin: 5px;
    img {
      width: 100%;
    }
  }
}
#blocRealisations2{
    padding: 30px 3%;
    display: flex;
    justify-content: space-between;
}
#blocRealisations3{
    padding: 30px 3%;
    display: flex;
    justify-content: space-between;
}
#blocRealisations .bloc, #blocRealisations2 .bloc, #blocRealisations3 .bloc {
    width: 24%;
}
#blocTriangle {
    display: flex;
    align-items: baseline;
    margin-top: -100px;
}
#blocTriangle img.img-responsive.mobile {
    display: none;
}


/*******************************************************************************
* Page À Propos
*******************************************************************************/

nav.module-menu .menu-navbar.nav-visible.shadow.flex.flex-column.menu3::before {
    content: '';
    width: 357px;
    height: 500px;
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.3s all ease-in-out;
    background-image: url(../images/apropos_header_img01.png);
}
#blocPrincipal_propos {
    padding: 130px 25% 0 25%;
    background-image: url(../images/apropos_section01_bg.jpg);
    background-size: cover;
    position: relative;
}
#blocPrincipal_propos .titre {
    display: flex;
    padding: 5px 0px 0 40px;
}
#blocPrincipal_propos .info {
    display: flex;
    justify-content: space-between;
    padding-left: 8%;
}
#blocPrincipal_propos h2 {
    color: #080808;
    font-size: 2.5rem;
    font-weight: 300;
    text-transform: uppercase;
}
#blocPrincipal_propos h3 {
    color: #080808;
    font-size: 2.5rem;
    font-weight: 300;
    text-transform: uppercase;
    padding-left: 23px;
}
#blocPrincipal_propos h3 span, #blocPrincipal_propos h2 span{
    font-weight: 400;
}
#blocPrincipal_propos .blocIntro .info .texte {
    margin-top: -85px;
    width: 63%;
}
#blocPrincipal_propos .blocIntro {
    margin-bottom: 200px;
}
#blocPrincipal_propos .blocIntro:nth-child(3) {
    margin-bottom: 100px;
}
#blocPrincipal_propos .blocIntro .info .texte p {
    font-weight: 400;
    margin-bottom: 0;
    color: #0b0b0b;
    font-size: 1rem;
}
#blocPrincipal_propos .blocLogo {
    display: flex;
    position: relative;
    padding-bottom: 50px;
}
#blocPrincipal_propos .blocLogo .bourgeois {
    position: absolute;
    right: -350px;
}
#blocPrincipal_propos::before {
    content: '';
    width: 600px;
    height: 1468px;
    position: absolute;
    right: 0;
    top: -5%;
    transition: 0.3s all ease-in-out;
    background-image: url(../images/apropos_header_img02.png);
}
#blocPrincipal_propos::after {
    content: '';
    width: 330px;
    height: 660px;
    position: absolute;
    left: 0;
    top: 40%;
    transition: 0.3s all ease-in-out;
    background-image: url(../images/apropos_section01_img01.png);
}


/*******************************************************************************
* Page Contact
*******************************************************************************/

nav.module-menu .menu-navbar.nav-visible.shadow.flex.flex-column.menu2::before {
    content: '';
    width: 357px;
    height: 500px;
    position: absolute;
    top: 0;
    left: 0;
    background-size: cover;
    transition: 0.3s all ease-in-out;
    background-image: url(../images/contact_header_img01.png);
}
#blocPrincipal_contact {
    padding: 130px 20% 0 20%;
    background-image: url(../images/contact_section01_bg.jpg);
    background-size: cover;
    position: relative;
}
#blocPrincipal_contact::before {
    content: '';
    width: 510px;
    height: 1016px;
    position: absolute;
    right: 0;
    top: 13%;
    transition: 0.3s all ease-in-out;
    background-image: url(../images/contact_section01_img01.png);
}
#blocPrincipal_contact .titre {
    display: flex;
    padding: 5px 0px 0 40px;
}
#blocPrincipal_contact .info {
    display: flex;
    justify-content: space-between;
    padding-left: 8%;
}
#blocPrincipal_contact .blocPersonnes {
    display: flex;
    justify-content: space-between;
    padding-left: 8%;
}
#blocPrincipal_contact h2 {
    color: #080808;
    font-size: 2.5rem;
    font-weight: 300;
    text-transform: uppercase;
}
#blocPrincipal_contact h3 {
    color: #080808;
    font-size: 2.5rem;
    font-weight: 300;
    text-transform: uppercase;
    padding-left: 25px;
}
#blocPrincipal_contact h3 span{
    font-weight: 400;
}
#blocPrincipal_contact .blocPersonnesRessources .blocPersonnes .bloc {
    padding-bottom: 35px;
    width: 35%;
}
#blocPrincipal_contact .blocPersonnesRessources .blocPersonnes .bloc h4 {
    font-weight: 600;
    font-size: 1rem;
}
#blocPrincipal_contact .blocPersonnesRessources .blocPersonnes .bloc p {
    font-weight: 400;
    margin-bottom: 0;
}
#blocPrincipal_contact .blocPersonnesRessources .blocPersonnes .bloc a {
    font-weight: 400;
    margin-bottom: 0;
    color: #ed1b24;
    font-size: 1rem;
}
#blocPrincipal_contact .blocPersonnesRessources .blocPersonnes .bloc a:hover {
    text-decoration: underline !important;
}
#blocPrincipal_contact .blocIntro .info h4 {
    font-weight: 600;
    font-size: 1rem;
    margin-bottom: 10px;
}
#blocPrincipal_contact .blocIntro .info p {
    font-weight: 400;
    margin-bottom: 0;
    line-height: 1.5;
}
#blocPrincipal_contact .blocPersonnesRessources {
    padding: 135px 0 100px 0;
}
#blocPrincipal_contact .blocIntro h5 {
    text-transform: uppercase;
    font-size: 1.25rem;
    position: relative;
    left: 80px;
    bottom: 90px;
}





/*******************************************************************************
* MOBILE OVERRIDES FOR ALL SECTIONS
*******************************************************************************/

@media screen and (max-width: 1810px) {

#blocPrincipal_ponceaux .titre h3 {
    width: 75%;
}
#blocPrincipal_ponceaux img.img-responsive.ligneRouge {
    left: 13%;
    top: 21%;
}
#blocPrincipal_ponceaux {
    padding: 13% 25% 10% 25%;
}
#blocPrincipal_prepare::before, #blocPrincipal_prefabrique::before, #blocPrincipal_ponceaux::before, #blocPrincipal_propos::before, #blocPrincipal_contact::before {
    display: none;
}
#blocFooter .blocDroite .blocTexte p:last-child {
    right: 120px;
}
#blocFooter .blocGauche .blocMenu {
    width: 90%;
}
}

@media screen and (max-width: 1755px) {

#blocFooter .blocGauche .blocMenu a, #blocFooter .blocGauche .bloc a {
    font-size: 18px;
}
#blocFooter .blocDroite {
    margin-top: 12px;
}
#blocFooter .blocDroite .blocTexte p {
    font-size: 20px;
}
#blocFooter .blocDroite .blocTexte p:last-child {
    right: 140px;
}
}

@media screen and (max-width: 1720px) {

nav.module-menu .menu-navbar ul.main-menu li.item {
    padding: 0 0.5rem;
}
#blocPrincipal_ponceaux .titre h3 {
    width: 80%;
}
#blocPrincipal_prefabrique img.img-responsive.ligneRouge {
    left: 12%;
    top: 37%;
}
}

@media screen and (max-width: 1675px) {

#blocFooter .blocGauche .blocMenu {
    width: 94%;
}
#blocFooter .blocDroite .blocTexte p:last-child {
    right: 120px;
}
#blocPrincipal_prepare img.img-responsive.ligneRouge {
    left: 13%;
    top: 14%;
}
}

@media screen and (max-width: 1600px) {

#blocFooter .blocGauche .blocMenu a, #blocFooter .blocGauche .bloc a {
    font-size: 17px;
}
#blocFooter .blocDroite .blocTexte p {
    font-size: 19px;
}
.main-content .owl-theme .custom-nav .owl-next, .main-content .owl-theme .custom-nav .owl-prev {
    top: 100px;
}
}

@media screen and (max-width: 1575px) {

nav.module-menu ul.main-menu li.item a div, nav.module-menu ul.main-menu li.item.dropdown a {
    font-size: 17px;
}
.main-content .owl-theme .custom-nav .owl-next, .main-content .owl-theme .custom-nav .owl-prev {
    top: 90px;
}
#blocPrincipal_prefabrique img.img-responsive.ligneRouge {
    left: 12%;
    top: 35%;
}
}

@media screen and (max-width: 1550px) {

nav.module-menu .menu-navbar .wrapper.left {
    flex: 15 0 auto;
}
nav.module-menu .menu-navbar ul.main-menu.flex.justify-between {
    justify-content: normal;
}
nav.module-menu .menu-navbar ul.main-menu.flex li.px6.item, nav.module-menu .menu-navbar ul.main-menu.flex li.px6.dropdown {
    padding-left: 0;
    padding-right: 25px;
}
#hero .slideshow-wrap .caption .row .col-xs-12 h2 {
    font-size: 4rem;
}
.slideshow-wrap .caption {
    top: 52.5%;
}
#blocPrincipal .blocProduit {
    float: none;
    width: 100%;
    border-right: none;
    border-bottom: 4px solid #ee2f37;
}
#blocPrincipal .blocBeton {
    width: 100%;
    float: none;
    padding-left: 0;
    padding-top: 50px;
}
#blocPrincipal .blocBeton img.img-responsive.imgVideo {
    margin: auto;
}
#blocPrincipal .blocBeton::before {
    bottom: 8.1%;
    right: -12px;
}
#blocPrincipal .blocBeton ul li br {
    display: none;
}
#blocPrincipal .blocBeton .blocInstallation img.img-responsive.imgSection02 {
    margin-top: 0;
    margin: auto;
}
#blocPrincipal .blocProduit .bloc.blocGauche {
    padding-left: 18%;
}
#blocPrincipal .blocProduit .bloc.blocGauche a h4 {
    font-weight: 300;
    text-transform: uppercase;
    font-size: 1.75rem;
    position: relative;
    left: 50px;
    padding-bottom: 50px;
    margin-top: 10px;
    color: #080808;
}
#blocFooter {
    flex-direction: column;
}
#blocFooter .blocGauche, #blocFooter .blocGauche .blocMenu {
    width: 87%;
}
#blocFooter .blocDroite {
    width: 100%;
    padding-top: 50px;
}
#blocFooter .blocGauche .blocMenu a, #blocFooter .blocGauche .bloc a {
    font-size: 19px;
}
#blocFooter .blocGauche, #blocFooter .blocGauche .blocMenu {
    width: 91%;
}
#blocFooter .blocDroite .blocTexte p:last-child {
    left: 260px;
}
#hero .slideshow-wrap.ls-direction-fix .ls-fullscreen-wrapper::before {
    right: -215px;
    background-image: url(../images/accueil_header_img02_1550.png) no-repeat;
}
#blocPrincipal_prepare .blocLogo .titre {
    width: 100%;
}
#blocPrincipal_prepare img.img-responsive.ligneRouge {
    position: absolute;
    left: 9%;
    top: 13%;
}
#blocIntro_prefabrique {
    padding: 80px 0 0 55%;
}
#blocIntro_prefabrique img.img-responsive.ligneRouge {
    position: absolute;
    left: 40%;
    top: 33%;
}
nav.module-menu .menu-navbar.nav-visible.shadow.flex.flex-column.menu4::before {
    content: '';
    width: 208px;
    height: 251px;
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.3s all ease-in-out;
    background-image: url(../images/glissierejersey_header_img01_1360.png) no-repeat;
}
#blocPrincipal_glissiere::before {
    content: '';
    width: 450px;
    height: 1210px;
    position: absolute;
    right: 0;
    top: 0%;
    transition: 0.3s all ease-in-out;
    background-image: url(../images/glissierejersey_header_img02_1360.png);
}
#blocPrincipal_glissiere img.img-responsive.ligneRouge {
    position: absolute;
    left: 9%;
    top: 18%;
}
.caption.container.slideshow2 {
    top: 44%;
    left: 54%;
}
.caption.container.slideshow2 .row.text img.img-responsive {
    bottom: -54px;
}
#blocRealisations, #blocRealisations2, #blocRealisations3 {
    //flex-direction: column;
}
#blocRealisations .bloc, #blocRealisations2 .bloc, #blocRealisations3 .bloc {
    //width: 50%;
    margin: auto;
}
nav.module-menu .menu-navbar.nav-visible.shadow.flex.flex-column.menu3::before {
    content: '';
    width: 300px;
    height: 996px;
    position: absolute;
    top: 0;
    left: 0;
    background-size: contain;
    background-repeat: no-repeat;
    transition: 0.3s all ease-in-out;
    background-image: url(../images/apropos_header_img01_1360.png) no-repeat;
}
#blocPrincipal_propos::before {
    content: '';
    width: 499px;
    height: 996px;
    position: absolute;
    right: 0;
    top: -5%;
    transition: 0.3s all ease-in-out;
    background-image: url(../images/apropos_header_img02_1360.png);
}
#blocPrincipal_propos .info {
    padding-left: 11%;
}
#blocPrincipal_contact::before {
    content: '';
    width: 336px;
    height: 669px;
    position: absolute;
    right: 0;
    top: 20%;
    transition: 0.3s all ease-in-out;
    background-image: url(../images/contact_section01_img01_1360.png);
}
#blocPrincipal_ponceaux::before {
    display: none;
}
#blocPrincipal_ponceaux .titre h3 {
    width: 90%;
}
#blocPrincipal_ponceaux img.img-responsive.ligneRouge {
    display: none;
}
#blocPrincipal_ponceaux .blocIntro p {
    width: 90%;
}
}

@media screen and (max-width: 1500px) {
  nav.module-menu ul.main-menu li.item a div {
      font-size: 15px;
  }
}

@media screen and (max-width: 1415px) {
    nav.module-menu ul.main-menu li.item a div {
        font-size: 15px!important;
    }
}

@media screen and (max-width: 1372px) {

    nav.module-menu ul.main-menu li.item a div {
        font-size: 15px;
    }
    nav.module-menu .menu-navbar .wrapper.left .logo {
        margin-left: 150px;
    }
}

@media screen and (max-width: 1460px) {

#blocPrincipal .blocProduit .bloc.blocGauche {
    padding-left: 14%;
}
#blocFooter .blocGauche, #blocFooter .blocGauche .blocMenu {
    width: 92%;
}
#blocFooter .blocDroite .blocTexte p {
    font-size: 20px;
}
#blocFooter .blocDroite .blocTexte h4 {
    font-size: 1.15rem;
}
#blocPrincipal .blocBourgeois .blocLien {
    right: -35px;
}
nav.module-menu.scrolled .menu-navbar .lowerpart.w100.flex.justify-between.px4 {
    padding-left: 7rem;
}
nav.module-menu .menu-navbar .lowerpart.w100.flex.justify-between.px4 {
    padding-left: 13rem;
}
#blocPrincipal .blocBeton .blocInstallation ul {
    padding-top: 15px;
}
#blocFooter .blocDroite .blocTexte p:last-child {
    left: 242px;
}
nav.module-menu .menu-navbar.shadow.flex.flex-column.nav-visible::before {
    background-image: url(../images/accueil_header_img01_1360.png) no-repeat;
}
.slideshow-wrap .caption {
    top: 49.5%;
}
#blocPrincipal .blocBeton::before, #blocPrincipal .blocBourgeois::before {
    display: none;
}
nav.module-menu {
    padding-top: 190px;
}
nav.module-menu .menu-navbar.nav-visible.shadow.flex.flex-column.menu6::before {
    content: '';
    width: 258px;
    height: 363px;
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.3s all ease-in-out;
    background-image: url(../images/betonprepare_header_img01_1360.jpg);
}
#blocPrincipal_prepare .liste ul li br {
    display: none;
}
#blocPrincipal_prepare .left-side {
    margin-right: 45px;
}
#blocPrincipal_prepare {
    padding: 13% 20% 0 20%;
    background-image: url(../images/betonprepare_section01_bg.jpg);
    background-size: cover;
    position: relative;
}
#blocPrincipal_prepare img.img-responsive.ligneRouge {
    position: absolute;
    left: 3%;
    top: 13%;
}
.caption.container.slideshow2 {
    left: 58%;
}
.caption.container.slideshow2 .row.text img.img-responsive {
    bottom: -28px;
}
#hero .slideshow-wrap .caption .row .col-xs-12 h2 span {
    font-weight: 500;
    color: #fff;
    font-size: 3rem;
}
.caption.container.slideshow2 {
    top: 45%;
    left: 62%;
}
.caption.container.slideshow2 .row.text img.img-responsive {
    bottom: -23px;
}
#blocPrincipal_propos .blocLogo .bourgeois {
    position: absolute;
    right: -300px;
}
nav.module-menu .menu-navbar.nav-visible.shadow.flex.flex-column.menu2::before {
    content: '';
    width: 264px;
    height: 456px;
    position: absolute;
    top: 0;
    left: 0;
    background-size: cover;
    transition: 0.3s all ease-in-out;
    background-image: url(../images/contact_header_img01_1360.png);
}
nav.module-menu .menu-navbar .wrapper.left .logo {
    margin-left: 0;
}
nav.module-menu .menu-navbar .lowerpart.w100.flex.justify-between.px4 {
    padding-left: 20rem;
}
nav.module-menu .menu-navbar .wrapper.left .logo {
    padding-left: 400px;
}
.main-content .owl-theme .custom-nav .owl-next, .main-content .owl-theme .custom-nav .owl-prev {
    top: 110px;
}
#blocPrincipal_prefabrique img.img-responsive.ligneRouge {
    left: 10%;
    top: 33%;
}
}

@media screen and (max-width: 1415px) {

nav.module-menu ul.main-menu li.item a div {
    font-size: 15px;
}
}

@media screen and (max-width: 1375px) {

nav.module-menu ul.main-menu li.item a div {
    font-size: 15px;
}
#blocPrincipal_ponceaux .titre h3 {
    width: 100%;
}
#blocPrincipal_prefabrique .titre h3 {
    width: 80%;
}
#blocPrincipal_prefabrique img.img-responsive.ligneRouge {
    left: 8%;
    top: 32%;
}
}

@media screen and (max-width: $size-md-max) {

#blocFooter .blocGauche, #blocFooter .blocGauche .blocMenu {
    width: 100%;
}
nav.module-menu.scrolled .menu-navbar .lowerpart.w100.flex.justify-between.px4 {
    padding-left: 0rem;
}
nav.module-menu .menu-navbar .lowerpart.w100.flex.justify-between.px4 {
    padding-left: 0rem;
}
nav.module-menu ul.main-menu li.item a div {
    font-size: 18px !important;
}
nav.module-menu.scrolled .menu-navbar {
    height: 180px;
}
#blocFooter {
    padding: 50px 35px;
}
#blocFooter .blocGauche .blocMenu a {
    padding-right: 25px;
}
#blocFooter .blocGauche .bloc {
    width: 30%;
}
#blocFooter .blocDroite .blocTexte p:last-child {
    left: 208px;
}
#blocPrincipal .blocBourgeois::before, #blocPrincipal .blocBeton::before, nav.module-menu .menu-navbar.shadow.flex.flex-column.nav-visible::before, #hero .slideshow-wrap.ls-direction-fix .ls-fullscreen-wrapper::before {
    display: none;
}
nav.module-menu .menu-navbar ul.main-menu.flex li.px6.item {
   padding-right: 1.2rem;
}
#blocPrincipal .blocProduit .bloc.blocGauche {
    padding-left: 0%;
}
#blocPrincipal .blocBourgeois {
    padding: 80px 0 315px 0;
}
#blocPrincipal .blocBourgeois .blocLien {
    right: -45px;
}
.slideshow-wrap .col-xs-12 img.img-responsive, #hero .slideshow-wrap .caption .row .col-xs-12 img.fleche {
    display: none;
}
#hero .slideshow-wrap .caption .row .col-xs-12 h2 {
    font-size: 3rem;
}
.slideshow-wrap .caption {
    top: 52.5%;
}
#blocPrincipal_prepare::before {
   display: none;
}
#blocPrincipal_prepare img.img-responsive.ligneRouge {
    position: absolute;
    left: 2%;
    top: 12.5%;
}
#blocPrincipal_prepare .blocIntro p {
    width: 100%;
}
#blocPrincipal_prepare .liste_installations::before {
    display: none;
}
#blocPrincipal_prepare .blocLogo .bourgeois {
    position: relative;
    left: 25%;
}
ul.main-menu.flex.justify-between {
    padding-left: 30px;
}
#blocIntro_prefabrique img.img-responsive.ligneRouge {
    position: absolute;
    left: -5%;
    top: 37%;
}
#blocIntro_prefabrique {
    padding: 80px 0 0 15%;
}
#blocIntro_prefabrique .titre h3 {
    width: 70%;
}
#blocPrincipal_glissiere::before {
    display: none;
}
#blocPrincipal_glissiere .blocLogo .bourgeois {
    position: relative;
    left: 80%;
}
#blocPrincipal_glissiere img.img-responsive.ligneRouge {
    position: absolute;
    left: 3%;
    top: 14%;
}
#blocPrincipal_propos .blocLogo {
    flex-direction: column;
}
#blocPrincipal_propos .blocLogo .bourgeois {
    position: initial;
}
#blocPrincipal_propos::before, #blocPrincipal_propos::after {
    display: none;
}
#blocPrincipal_propos {
    padding: 130px 15% 0 15%;
}
#blocPrincipal_propos .blocIntro {
    margin-bottom: 125px;
}
#blocPrincipal_contact::before {
    display: none;
}
#blocPrincipal_contact {
    padding: 130px 15% 0 15%;
}
nav.module-menu .menu-navbar .wrapper.left .logo {
    padding-left: 0;
}
nav.module-menu .menu-navbar .lowerpart.w100.flex.justify-between.px4 {
    padding-left: 0 !important;
}
nav.module-menu ul.main-menu li.item a div {
    font-size: 16px;
}
nav.module-menu .menu-navbar.nav-invisible {
    top: -110px;
}
#blocPrincipal_ponceaux {
    padding: 13% 25% 15% 25%;
}
.blocLogo_ponceaux {
    right: 4%;
    top: 89%;
}
.main-content .owl-theme .custom-nav .owl-next, .main-content .owl-theme .custom-nav .owl-prev {
    top: 95px;
}
}

@media screen and (max-width: 1024px) {

ul.main-menu.flex.justify-between {
    display: none;
}
nav.module-menu .menu-toggle {
    margin-top: -80px;
}
#blocPrincipal_ponceaux {
    padding: 15% 10%;
}
nav.module-menu .menu-navbar.nav-invisible {
    top: -160px;
}
nav.module-menu {
    padding-top: 0;
}
nav.module-menu .menu-navbar, nav.module-menu.scrolled .menu-navbar{
    height: 120px;
}
#blocPrincipal_prefabrique {
    padding: 20% 10% 2% 10%;
}
#blocPrincipal_prefabrique img.img-responsive.ligneRouge, #blocPrincipal_prepare img.img-responsive.ligneRouge {
    display: none;
}
#blocPrincipal_prepare {
    padding: 15% 10% 0 10%;
}
#blocPrincipal_prepare .liste ul li, #blocPrincipal_prepare .liste_installations ul li {
    padding-left: 0;
}
#blocPrincipal_prepare .blocLogo .titre {
    width: 45%;
}
#blocPrincipal_prepare .blocLogo h4 {
    font-size: 1.5rem;
}
#blocPrincipal_prepare .titreFabriquons, #blocPrincipal_prepare .titreInstallations {
    padding: 50px 0 25px 0;
}
#blocPrincipal_prepare .blocLogo {
    padding: 55px 0 30px 0;
}
}

@media screen and (max-width: $size-sm-max) {

#blocPrincipal .blocProduit .bloc.blocGauche {
    float: none;
    padding-right: 0px;
}
#blocPrincipal .blocProduit .bloc img {
    margin: auto;
}
#blocPrincipal .blocProduit .bloc a h4, #blocPrincipal .blocProduit .bloc.blocGauche a h4 {
    left: 150px;
}
#blocRealisations .bloc, #blocRealisations2 .bloc, #blocRealisations3 .bloc {
    width: 45%;
    margin: auto;
}
.blocBourgeois p br {
    display: none;
}
#blocPrincipal .blocBourgeois p, #blocPrincipal .blocProduit p {
    font-size: 20px;
    text-align: center;
}
nav.module-menu ul.main-menu.flex.justify-between {
    display: none;
}
nav.module-menu .menu-navbar .main-menu.right {
    opacity: 0;
}
nav.module-menu {
    padding-top: 115px;
}
#blocPrincipal .blocBeton .blocInstallation ul {
    left: 20%;
    width: 60%;
}
#blocFooter .blocGauche .blocMenu a {
    padding-right: 45px;
    line-height: 2;
}
#blocFooter .blocGauche .bloc {
    width: 40%;
}
.slideshow-wrap .caption {
    top: 51.5%;
}
#hero .slideshow-wrap .caption .row .col-xs-12 h2 {
    font-size: 2.75rem;
}
#blocPrincipal_prepare {
    padding: 13% 10% 0 10%;
    background-image: url(../images/betonprepare_section01_bg.jpg);
    background-size: cover;
    position: relative;
}
#blocPrincipal_prepare img.img-responsive.ligneRouge {
    display: none;
}
#blocPrincipal_prepare .blocLogo .bourgeois {
    position: relative;
    left: 5%;
}
#blocPrincipal_prepare .liste ul li, #blocPrincipal_prepare .liste_installations ul li {
    padding-bottom: 10px;
}
#blocIntro_prefabrique img.img-responsive.ligneRouge {
    display: none;
}
#blocPrincipal_glissiere img.img-responsive.ligneRouge {
    display: none;
}
#blocPrincipal_glissiere .blocLogo::before {
    display: none;
}
#blocPrincipal_glissiere {
    padding: 13% 10% 0 10%;
}
#blocPrincipal_glissiere .blocLogo .bourgeois {
    left: 60%;
}
#hero .slideshow-wrap .caption .row .col-xs-12 h2 span {
    font-size: 2.6rem;
}
.caption.container.slideshow2 {
    top: 43%;
    left: 63%;
}
.caption.container.slideshow2 .row.text img.img-responsive {
    bottom: -12px;
}
#blocPrincipal_propos {
    padding: 130px 10% 0 10%;
}
#blocPrincipal_propos .blocIntro .info .texte {
    margin-top: -110px;
    width: 81%;
}
#blocPrincipal_propos .info {
    padding-left: 13%;
}
#blocPrincipal_contact {
    padding: 130px 10% 0 10%;
}
#blocPrincipal_contact .blocPersonnes {
    padding-top: 15px;
}
#blocPrincipal_ponceaux {
    padding: 10% 5% 22% 5%;
}
.main-content .owl-theme .custom-nav .owl-next, .main-content .owl-theme .custom-nav .owl-prev {
    top: 80px;
}
#blocPrincipal_prefabrique {
    padding: 10% 5% 2% 5%;
}
#blocPrincipal_prefabrique .texte p {
    width: 80%;
}
#blocPrincipal_prepare {
    padding: 10% 5% 0 5%;
}
}

@media screen and (max-width: $size-xs-max) {

#blocPrincipal {
    padding: 110px 30px 30px 30px;
}
#blocPrincipal .blocProduit p {
    width: 100%;
}
#blocPrincipal .blocProduit .bloc a h4, #blocPrincipal .blocProduit .bloc.blocGauche a h4 {
    left: 50px;
}
#blocPrincipal .blocBeton .blocInstallation ul {
    left: 5%;
    width: 96%;
}
#blocPrincipal .blocBourgeois .blocLien {
    right: 20px;
}
#blocFooter .blocGauche .bloc a {
    padding-bottom: 30px;
}
#blocFooter .blocGauche .blocMenu a {
    font-size: 16px;
    padding-right: 20px;
}
#blocFooter .blocDroite .blocTexte p:last-child {
    left: 35px;
}
#blocFooter .blocDroite .blocTexte h4 {
    font-size: 1.5rem;
}
#blocFooter .blocDroite .blocTexte p {
    font-size: 18px;
}
#blocPrincipal .blocBeton .blocInstallation h4.capacites, #blocPrincipal .blocBeton .blocInstallation h4.installations {
    font-size: 18px;
}
#blocPrincipal .blocBourgeois h3 {
    font-size: 1.75rem;
}
#hero .slideshow-wrap .caption .row .col-xs-12 h2 {
    font-size: 4rem;
}
.slideshow-wrap .caption {
    top: 60.5%;
}
#blocPrincipal_prepare .titre h3 {
    width: 100%;
}
#blocPrincipal_prepare .liste {
    flex-direction: column;
}
#blocPrincipal_prepare .blocLogo {
    padding: 50px 0 30px 0;
    flex-direction: column;
}
#blocPrincipal_prepare .blocLogo .bourgeois {
    position: initial;
    padding-top: 50px;
}
#blocIntro_prefabrique {
    padding: 80px 0 0 5%;
}
#blocIntro_prefabrique .titre h3 {
    width: 85%;
}
#blocPrincipal_glissiere {
    padding: 13% 5% 0 5%;
}
#blocPrincipal_glissiere .blocIntro p {
    width: 95%;
}
#blocPrincipal_glissiere .titreModele h3 br {
    display: none;
}
#blocPrincipal_glissiere .blocModele {
    flex-direction: column;
}
#blocPrincipal_glissiere .blocModele .left-side, #blocPrincipal_glissiere .blocModele .right-side {
    width: 100%;
}
#blocPrincipal_glissiere .blocLogo .bourgeois {
    left: 10%;
}
#hero .slideshow-wrap .caption .row .col-xs-12 h2 span {
    font-size: 3.5rem;
    color: #080808;
}
.caption.container.slideshow2 .row.text img.img-responsive {
    display: none;
}
.caption.container.slideshow2 {
    top: 43%;
    left: 52%;
}
#blocRealisations .bloc, #blocRealisations2 .bloc, #blocRealisations3 .bloc {
    width: 100%;
    margin: auto;
}
#blocPrincipal_propos {
    padding: 130px 5% 0 5%;
}
#blocPrincipal_propos .titre {
    padding: 5px 0px 0 7px;
}
#blocPrincipal_contact .info {
    flex-direction: column;
}
#blocPrincipal_contact .blocPersonnes {
    flex-direction: column;
}
#blocPrincipal_contact .blocPersonnesRessources .blocPersonnes .bloc {
    width: 100%;
}
#blocPrincipal_contact {
    padding: 130px 5% 0 5%;
}
#blocPrincipal_contact .blocIntro h5 {
    font-size: 1rem;
}
#blocPrincipal_ponceaux {
    padding: 10% 5% 50% 5%;
}
#blocPrincipal_ponceaux .titre h3 {
    font-size: 3rem;
}
#blocPrincipal_ponceaux .titreFabriquons_ponceaux {
    padding: 0;
}
#blocPrincipal_ponceaux .liste {
    padding-top: 25px;
}
#blocPrincipal_ponceaux .liste ul li {
    line-height: 2.2;
    margin-bottom: 7px;
}
.main-content .item {
    width: 100%;
}
.main-content .owl-theme .custom-nav .owl-next {
    right: 0;
}
.main-content .owl-theme .custom-nav .owl-next, .main-content .owl-theme .custom-nav .owl-prev {
    top: 55px;
}
.main-content .owl-theme .custom-nav .owl-next, .main-content .owl-theme .custom-nav .owl-prev {
    top: 50px;
}
#blocPrincipal_prefabrique .titre h3, #blocPrincipal_prefabrique .texte p {
    width: 100%;
}
#blocPrincipal_prepare .blocLogo .titre {
    width: 100%;
}
}

@media screen and (max-width: 360px) {

#blocFooter .blocGauche .bloc {
    width: 48%;
}
#blocPrincipal .blocBourgeois .blocLien .images img.logoQuali {
    padding-right: 50px;
}
.slideshow-wrap .caption {
    top: 54.5%;
}
#blocPrincipal_glissiere .blocLogo .bourgeois {
    left: 3%;
}
#blocPrincipal_glissiere .titre h3 {
    width: 90%;
}
#hero .slideshow-wrap .caption .row .col-xs-12 h2 span {
    font-size: 3rem;
    color: #080808;
}
#blocPrincipal_propos h3 {
    font-size: 2rem;
}
#blocPrincipal_ponceaux .titre h3 {
    font-size: 2.5rem;
}
#blocPrincipal_prefabrique .titre h3 {
    font-size: 3.4rem;
}
}


///////////////////////////////////////////////////////////////////////////////
/*style pages mediatask remplissage*/
///////////////////////////////////////////////////////////////////////////////
section.section-menu-filter {
  padding: 80px 0;
  ul.menu-filter {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    list-style: none;
  }
}

div.section-products-liste {
  margin-bottom: 50px;
  div.grid-item {
    text-align: center;
    margin: 0 auto;

    h4.item-title {
      color: $color-black;
      font-weight: 700;
      line-height: 1.3;
      padding: 15px 0;
    }
    div.box-description p {
      font-size: 16px;
      margin: 0;
      font-weight: 400;
      line-height: 1.2;
      padding: 4px 0;;
    }
    div.img-wrapper {
      transition: .3s;
      overflow: hidden;
      img {
        transition: .3s;
      }
    }

    &:hover {
      // div.img-wrapper {
      // }
    }

  }

}
/*****************************************************/
/*  POPUP
/*****************************************************/
.product-popup {
    position: relative;
    background: white;
    padding: 20px;
    width: auto;
    max-width: 90%;
    margin: 20px auto;

    .product-wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .product-image, .product-info {
            width: 50%;
            padding: 10px;
            @media screen and (max-width: $size-xs-max){
              width: 80%;
            }
        }
        .product-info {
            padding: 10px 0;
        }

    }

    img.mfp-img {
        padding: 0;
    }
    .title {
        padding: 20px 0;
        font-size: 20px;
        font-weight: 700;
        text-transform: uppercase;
    }

    div.info {
        @extend .flex;
        @extend .items-center;
        @extend .justify-between;
    }
    div.description {
        padding: 10px 0;
        //@extend .font-1;

        text-align: justify;
        p {
          font-size: 18px;
          color: $color-grey;
          font-weight: 400;
        }
    }
    @media screen and (max-width: $size-xs-max) {
        .title,.categories, div.description {
            font-size: 18px;
            text-align: center;
            padding: 0;
            p {text-align: center;}
        }
        .product-wrapper {
            flex-direction: column;
            .product-info .info {
                display: flex;
                flex-direction: column;
            }
        }
    }
}

.mfp-close-btn-in .mfp-close {
    // color: $color-yellow;
    height: 24px;
    line-height: 24px;
    width: 24px;
}
button.mfp-arrow-right {
    // right: calc(100vw / 2 - 420px);
}
button.mfp-arrow-left {
    // left: calc(100vw / 2 - 420px);
}
