.btn {
    //background-color: $color-white;
    color: $color-grey-dark;
    padding: 5px 15px;
    font-size: $font-size-18;
    width: 100%;
    text-transform: uppercase;
    transition: all 0.2s ease-in;
    border-radius: 0;
    &.selected {
      background-color: $color-primary;
      color: $color-white;
      box-shadow: 1px 3px 7px $color-grey;
    }

    &:hover {
        transition: all 0.2s ease-in;
        text-shadow: 0 0 5px $color-grey;
    }
}
